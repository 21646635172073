import { css } from 'styled-components';
import { focus, gray1, gray2, gray5 } from '../globals/colors';
import alpha from '../utility/alpha';

export const flexCenterMixin = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const bubbleMixin = css`
    height: 100%;
    width: 100%;
    background: ${gray1};
    border-radius: 999px;
    border: 2px solid white;
    cursor: pointer;
`;

export const bubbleEventMixin = css`
    &:hover {
        background: ${gray2};
    }

    &:focus {
        border: 2px solid ${focus};
        outline: none;
    }
`;

export const labeledIconFrameMixin = css`
    border: 2px solid white;
    width: 8em;

    &:focus {
        border-color: ${focus};
        box-shadow: none;
    }
`;

/**
 * @param {import('styled-components').CSSObject} mixinContent
 * @param {Number} maxWidth
 * @returns {import('styled-components').CSSObject}
 */
export const mobileMixin = (mixinContent, maxWidth = 600) => css`
    @media screen and (max-width: ${maxWidth}px) {
        ${mixinContent}
    }
`;

export const breakpoints = [600, 2000];

/**
 * Creates media query rules based on specified breakpoints.
 * @example
 * const breakpoints = [1280, 1600];
 * const Component = styled.div`
 *     ${responsivePropertyMixin(breakpoints, [
 *         ['font-size', '.6rem', '.8rem', '1rem'],
 *         ['padding', '0 .5em', '.5em .75em', '1em']
 *     ])}
 * `;
 * @param {number[]} breakpoints
 * @param {(string | number)[]} rules
 * @returns {string}
 */
export function responsivePropertyMixin(breakpoints, rules) {
    const atMediaPrefix = '@media screen and ';
    const minWidth = (width) => `(min-width:${width}px)`;
    const maxWidth = (width) => `(max-width:${width}px)`;
    const inBrackets = (content) => `{${content}}`;

    const breakpointRules = [0, ...breakpoints].map((_, index) => {
        return rules.reduce((result, [property, ...values]) => {
            if (values[index] != null) {
                return `${result}${property}:${values[index]};`;
            }

            return result;
        }, '');
    });

    const firstRule = [
        atMediaPrefix,
        maxWidth(breakpoints[0]),
        inBrackets(breakpointRules[0])
    ].join('');

    const lastRule = [
        atMediaPrefix,
        minWidth(breakpoints[breakpoints.length - 1] + 1),
        inBrackets(breakpointRules[breakpointRules.length - 1])
    ].join('');

    let middleRules = '';
    if (breakpoints.length > 1) {
        middleRules = breakpointRules.slice(1, -1).map((_, index) => {
            return [
                atMediaPrefix,
                minWidth(breakpoints[index] + 1),
                ' and ',
                maxWidth(breakpoints[index + 1]),
                inBrackets(breakpointRules[index + 1])
            ].join('');
        }).join('');
    }

    const mediaQueries = [firstRule, middleRules, lastRule].join('');

    return mediaQueries;
}

export const visuallyHiddenMixin = css`
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
`;

/**
 * (1) a fallback because Edge does not support space-evenly
 */
export const commoditiesWrapperMixin = css`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    align-self: center;
    flex-wrap: wrap;
    width: 100%;

    @supports not (justify-content: space-evenly) {
        justify-content: space-around; /* (1) */
    }

    label{
        margin: .25em;
    }

    input[type='radio']:checked + label {
        background: ${alpha(gray5, .1)};
        box-shadow: 0 0 0 1px ${focus};
    }

    input[type='radio']:focus + label {
        background: ${alpha(gray5, .1)};
        box-shadow: 0 0 0 1px ${focus};
    }
`;
