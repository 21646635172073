import { createContext, useEffect, useState } from 'react';
import { GOOGLE_GEOCODE_API_KEY } from '../globals/env';

export const GoogleApiContext = createContext({
    loaded: true
});

export function useGoogleInit() {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (window?.google?.maps?.places) {
            setLoaded(true);
        } else {
            window.googleLoaded = function googleLoaded() {
                setLoaded(true);
            };

            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_GEOCODE_API_KEY}&libraries=places&callback=googleLoaded`;
            document.body.appendChild(script);
        }
    }, []);

    return {
        loaded
    };
}
